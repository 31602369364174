import {
  Heading,
  Text,
  Code,
  Link,
  UnorderedList,
  OrderedList,
  ListItem,
} from "@chakra-ui/react";
import ReactMarkdown from "react-markdown";

const markdownComponents = {
  h1: (props: any) => <Heading as="h1" fontSize="2xl" {...props} />,
  h2: (props: any) => <Heading as="h2" fontSize="xl" {...props} />,
  h3: (props: any) => <Heading as="h3" fontSize="lg" {...props} />,
  h4: (props: any) => <Heading as="h4" fontSize="md" {...props} />,
  h5: (props: any) => <Heading as="h5" fontSize="sm" {...props} />,
  h6: (props: any) => <Heading as="h6" fontSize="xs" {...props} />,
  p: (props: any) => <Text variant="caption" {...props} />,
  code: (props: any) => <Code {...props} />,
  a: (props: any) => (
    <Link isExternal color="brand.500" fontWeight="medium" target="_blank" {...props} />
  ),
  ul: (props: any) => <UnorderedList {...props} />,
  ol: (props: any) => <OrderedList {...props} />,
  li: (props: any) => <ListItem {...props} />,
};

// ReactMarkdown compiles a markdown string into a JSX component tree, mapping each element to a Chakra UI component.
function Markdown(props: { children: string | null | undefined }) {
  return <ReactMarkdown components={markdownComponents}>{props.children}</ReactMarkdown>;
}

export default Markdown;
