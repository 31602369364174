import { useMemo, useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuDivider,
  SkeletonText,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
  Tab,
  Tag,
  Thead,
  Tr,
  Th,
  Tbody,
  Flex,
  Heading,
  Stack,
  Text,
  Divider,
  useBoolean,
  useToast,
} from "@chakra-ui/react";
import { MoreVert, Replay } from "@material-ui/icons";
import { useForm, useWatch } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  EndpointUpdate,
  ListResponseEndpointOut,
  MessageStatus,
  MessageAttemptListOptions,
  EndpointOut,
} from "svix";
import { EndpointApi, EnvironmentSettingsApi } from "svix/dist/openapi";

import { useSearch } from "@svix/common/hooks/search";
import {
  extractSvixPlayToken,
  getSvixPlayViewUrl,
  isSvixPlayUrl,
} from "@svix/common/play";
import { capitalize, formatDateTime, humanize } from "@svix/common/utils";
import Card from "@svix/common/widgets/Card";
import ConfirmationDialog from "@svix/common/widgets/ConfirmationDialog";
import {
  DateFilterChoice,
  useInitialDateFilter,
  NOW_FILTER,
  EARLIEST_DATE_FILTER,
  encodeCustomDateFilter,
  LAST_MONTH_FILTER,
} from "@svix/common/widgets/DateFilter/DateFilter";
import LoadingIndicator from "@svix/common/widgets/LoadingIndicator";
import { MetaTitle } from "@svix/common/widgets/MetaTitle";
import {
  PageToolbar,
  BreadcrumbItem,
  Breadcrumbs,
  BreadcrumbItemWithId,
} from "@svix/common/widgets/PageToolbar";
import ResourceNotFound from "@svix/common/widgets/ResourceNotFound";
import Stat from "@svix/common/widgets/Stat";
import Table from "@svix/common/widgets/Table";
import TableCell from "@svix/common/widgets/TableCell";

import { getSvix, SinkOut, SinksApi } from "src/api";
import { routeResolver } from "src/App";
import { useAppPagination, useAppQuery } from "src/hooks/api";
import { useAppSelector } from "src/hooks/store";
import { isEE, useLoadingManual } from "src/utils";
import ReadOnlyTooltip from "src/widgets/ReadOnlyTooltip";
import CustomHeaders from "./CustomHeaders";
import Description from "./Description";
import EndpointTableRow from "./EndpointRow";
import EndpointStatChart, { getFormattedStats } from "./EndpointStats";
import EndpointUrl from "./EndpointUrl";
import MessageStatusFilter from "./MessageStatusFilter";
import Channels from "./properties/Channels";
import EndpointEventTypes from "./properties/EventTypes";
import SigningSecret from "./properties/SigningSecret";
import RateLimit from "./RateLimit";
import RecoverModal from "./RecoverModal";
import ReplayModal from "./ReplayModal";
import SendExample from "./SendExample";
import TransformationCard from "./TransformationCard";
import FilterMenu, { FilterType } from "../../components/FilterMenu";

function useInitialStatus() {
  const status = useSearch("status");
  if (status) {
    return Number(status);
  }
  return undefined;
}

function useInitialEventTypes() {
  const savedVal = useSearch("eventTypes");
  if (!savedVal) {
    return [] as string[];
  }

  return savedVal.split(",");
}

const endpointTabs = ["overview", "advanced", "testing"];

export default function EndpointScreen({ isSink = false }: { isSink?: boolean }) {
  const queryClient = useQueryClient();
  const history = useHistory();
  const user = useAppSelector((state) => state.auth.user)!;
  const stringsOverride = useAppSelector((state) => state.embedConfig.stringsOverrides);
  const { endpId } = useParams<{ endpId: string }>();
  const queryKey = ["endpoints", endpId];
  const transformationsEnabled = useAppSelector(
    (state) => state.settings.enableTransformations
  );

  const [filterStatus, setFilterStatus] = useState<MessageStatus | undefined>(
    useInitialStatus()
  );
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [showRecoverModal, setShowRecoverModal] = useBoolean();
  const [showReplayModal, setShowReplayModal] = useBoolean();
  const isReadOnly = useAppSelector((state) => state.embedConfig.isReadOnly);

  const { data: endpointOrSink, error } = useAppQuery(
    queryKey,
    async () => {
      if (isSink) {
        const api = SinksApi();
        const res = (await api.getSink(user.app.id, endpId)) as SinkOut;
        return res;
      } else {
        const api = getSvix();
        return api.endpoint.get(user.app.id, endpId);
      }
    },
    {
      placeholderData: () =>
        queryClient
          .getQueryData<ListResponseEndpointOut>("endpoints")
          ?.data.find((d) => d.id === endpId),
    }
  );

  const endpoint: EndpointOut | undefined =
    endpointOrSink && endpointFromSink(endpointOrSink);

  const defaultValues = {
    eventTypes: useInitialEventTypes(),
    before: useInitialDateFilter("before", NOW_FILTER),
    after: useInitialDateFilter("after", EARLIEST_DATE_FILTER),
    channels: useSearch("channels") ?? "",
    tags: useSearch("tags") ?? "",
  };

  const formCtx = useForm({ defaultValues });

  let filterCount = 0;

  const eventTypes = useWatch({
    control: formCtx.control,
    name: "eventTypes",
    defaultValue: defaultValues.eventTypes,
  });
  filterCount += eventTypes.length;

  const afterDateFilter: DateFilterChoice = useWatch({
    control: formCtx.control,
    name: "after",
    defaultValue: defaultValues.after,
  });
  if (afterDateFilter.value !== EARLIEST_DATE_FILTER.value) {
    filterCount++;
  }

  const beforeDateFilter: DateFilterChoice = useWatch({
    control: formCtx.control,
    name: "before",
    defaultValue: defaultValues.before,
  });
  if (beforeDateFilter.value !== NOW_FILTER.value) {
    filterCount++;
  }

  const channel = useWatch({
    control: formCtx.control,
    name: "channels",
    defaultValue: defaultValues.channels,
  });
  if (channel) {
    filterCount++;
  }

  const tags = useWatch({
    control: formCtx.control,
    name: "tags",
    defaultValue: defaultValues.tags,
  });
  if (tags) {
    filterCount++;
  }

  const toast = useToast();

  const tryShortenQuery = (current?: Date) => {
    if (current) {
      const newDate = new Date(current);
      newDate.setDate(current.getDate() + 7);
      if (newDate > new Date()) {
        return undefined;
      }
      return newDate;
    }
    return LAST_MONTH_FILTER.getDate();
  };

  const [msgs, messagesCtx] = useAppPagination(
    [
      ...queryKey,
      "messages",
      String(filterStatus ?? "all"),
      afterDateFilter.value,
      beforeDateFilter.value,
      channel,
      tags,
      eventTypes.join(","),
    ],
    async (iterator) => {
      const api = getSvix();
      let after = afterDateFilter.getDate();
      let canShorten = true;
      while (canShorten) {
        try {
          const res = await api.messageAttempt.listByEndpoint(user.app.id, endpId, {
            iterator,
            limit: 20,
            status: filterStatus,
            channel: channel || undefined,
            before: beforeDateFilter.getDate(),
            after,
            eventTypes,
            tag: tags || undefined, // hidden from public API
            withMsg: true, // hidden from public API
          } as MessageAttemptListOptions & { withMsg: true; tag: string | undefined });
          formCtx.setValue(
            "after",
            after ? encodeCustomDateFilter(after) : EARLIEST_DATE_FILTER
          );
          return res;
        } catch (e) {
          if (e?.body?.code === "too_much_data" || e?.body?.code === "query_timeout") {
            // Shorten the date range in 7 day increments until the query works
            const shortened = tryShortenQuery(after);
            canShorten = !!shortened;
            after = shortened;
            toast({
              duration: 4000,
              status: "info",
              title: "There is too much data to fulfill the request",
              description: "Shortening the date range by 7 days.",
            });
          } else {
            throw e;
          }
        }
      }

      return {
        data: [],
        done: true,
        iterator: undefined,
      };
    }
  );

  const { data: stats } = useAppQuery(
    [...queryKey, "stats"],
    async () => {
      const sv = getSvix();
      const config = sv._configuration;
      const endpApi = new EndpointApi(config);
      return endpApi.v1EndpointGetStats({
        appId: user.app.id,
        endpointId: endpId,
      });
    },
    {
      enabled: !!endpoint,
    }
  );

  const { data: orgSettings } = useAppQuery(
    ["orgSettings"],
    async () => {
      const sv = getSvix();
      const config = sv._configuration;
      const api = new EnvironmentSettingsApi(config);
      return api.v1EnvironmentGetSettings({});
    },
    {
      // since org settings should not likely to change frequently, don't refetch
      // on re-mount
      staleTime: Infinity,
    }
  );

  const [, , setDisabled] = useLoadingManual(
    async (disabled: boolean) => {
      const api = getSvix();
      const patchedEndpoint = { ...endpoint, disabled };
      if (endpoint!.filterTypes && endpoint!.filterTypes.length === 0) {
        delete patchedEndpoint["filterTypes"];
      }

      await api.endpoint.update(user.app.id, endpId, patchedEndpoint as EndpointUpdate);
      queryClient.invalidateQueries(queryKey);
    },
    [endpId, endpoint]
  );

  const [, , deleteEndpoint] = useLoadingManual(async () => {
    const api = getSvix();
    await api.endpoint.delete(user.app.id, endpId);
    history.replace(routeResolver.getRoute("endpoints"));
  }, []);

  const { hash } = useLocation();
  const tabsIndex = useMemo(() => {
    switch (hash) {
      case "#advanced":
        return 1;
      case "#testing":
        return 2;
      default:
        return 0;
    }
  }, [hash]);

  if (error) {
    return (
      <ResourceNotFound
        resourceName="endpoint"
        to={routeResolver.getRoute("endpoints")}
      />
    );
  }

  if (!endpoint) {
    return <LoadingIndicator />;
  }

  const filters: FilterType[] = ["date", "eventType"];
  if (orgSettings?.enableChannels) {
    filters.push("channels");
  }
  if (!isEE) {
    filters.push("tags");
  }

  return (
    <>
      <MetaTitle path={[endpoint.uid ?? humanize(endpId), "Endpoints", user.app.name]} />
      <PageToolbar>
        <Breadcrumbs>
          <BreadcrumbItem to={routeResolver.getRoute("endpoints")}>
            Endpoints
          </BreadcrumbItem>
          <BreadcrumbItemWithId identifier={endpId} uid={endpoint.uid} />
          {endpoint.disabled && (
            <Tag size="md" colorScheme="red" ml={2}>
              Disabled
            </Tag>
          )}
        </Breadcrumbs>
        <Flex flexGrow={1} />
        <Box>
          <Menu placement="bottom-end">
            <MenuButton as={IconButton} variant="rounded" data-cy="options-button">
              <MoreVert />
            </MenuButton>
            <MenuList data-cy="options-menu">
              {isSvixPlayUrl(endpoint.url) && !isEE && (
                <>
                  <MenuItem
                    as="a"
                    href={getSvixPlayViewUrl(extractSvixPlayToken(endpoint.url) || "")}
                    target="_blank"
                    rel="noreferrer"
                  >
                    View in Svix Play
                  </MenuItem>
                  <MenuDivider />
                </>
              )}
              <MenuItem onClick={setShowRecoverModal.on}>
                Recover failed messages...
              </MenuItem>
              {!isEE && (
                <>
                  <MenuItem onClick={setShowReplayModal.on}>
                    Replay missing messages...
                  </MenuItem>
                </>
              )}
              <ReadOnlyTooltip readOnly={isReadOnly}>
                <MenuItem
                  isDisabled={isReadOnly}
                  onClick={() => setDisabled(!endpoint.disabled)}
                >
                  {endpoint.disabled ? "Enable" : "Disable"} Endpoint
                </MenuItem>
              </ReadOnlyTooltip>
              <ReadOnlyTooltip readOnly={isReadOnly}>
                <MenuItem
                  isDisabled={isReadOnly}
                  textColor="text.danger"
                  onClick={() => setDeleteDialogOpen(true)}
                >
                  Delete
                </MenuItem>
              </ReadOnlyTooltip>
            </MenuList>
          </Menu>
        </Box>
      </PageToolbar>
      <Grid
        gridTemplateColumns={{
          sm: "minmax(0, 1fr)",
          md: "minmax(0, 3fr) minmax(240px, 1fr)",
        }}
        gap={8}
      >
        <Tabs
          variant="enclosed"
          index={tabsIndex}
          onChange={(i) => history.push(`#${endpointTabs[i]}`)}
        >
          {isSink ? (
            <Heading mb={6} as="h1" size="sm" isTruncated>
              {endpoint.url}
            </Heading>
          ) : (
            <EndpointUrl endpoint={endpoint} />
          )}
          <TabList>
            <Tab
              _selected={{
                border: "1px solid",
                borderColor: "background.modifier.border",
                borderBottomColor: "background.primary",
              }}
            >
              Overview
            </Tab>
            <Tab
              _selected={{
                border: "1px solid",
                borderColor: "background.modifier.border",
                borderBottomColor: "background.primary",
              }}
            >
              Advanced
            </Tab>
            <Tab
              _selected={{
                border: "1px solid",
                borderColor: "background.modifier.border",
                borderBottomColor: "background.primary",
              }}
            >
              Testing
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Stack spacing={4}>
                <Description endpoint={endpoint} />
                {isSink && endpointOrSink && (
                  <SinkConfiguration sink={endpointOrSink as SinkOut} />
                )}
                <Card title="Attempt Delivery Stats" mt={6}>
                  <EndpointStatChart
                    stats={getFormattedStats(stats)}
                    isLoading={!stats}
                  />
                </Card>
              </Stack>
            </TabPanel>
            <TabPanel>
              <Stack spacing={4}>
                <RateLimit endpoint={endpoint} />
                <CustomHeaders endpoint={endpoint} />
                {transformationsEnabled && <TransformationCard endpoint={endpoint} />}
              </Stack>
            </TabPanel>
            <TabPanel>
              <SendExample endpoint={endpoint} />
            </TabPanel>
          </TabPanels>
        </Tabs>
        <Stack spacing={4}>
          <Stat name="Creation Date">{formatDateTime(endpoint.createdAt)}</Stat>
          <Divider />
          <Stat name="Last Updated">{formatDateTime(endpoint.updatedAt)}</Stat>
          {orgSettings?.enableChannels && (
            <>
              <Divider />
              <Channels endpoint={endpoint} />
            </>
          )}
          <Divider />
          <EndpointEventTypes endpoint={endpoint} />
          <Divider />
          <SigningSecret />
        </Stack>
      </Grid>

      <Flex alignItems="center" mt={8} mb={4} gridGap={2}>
        <Heading as="h2" size="sm">
          Message Attempts
        </Heading>
        <Flex flexGrow={1} />
        <IconButton
          size="sm"
          aria-label="Refresh"
          variant="outline"
          isLoading={messagesCtx.isFetching}
          onClick={messagesCtx.refetch}
        >
          <Replay style={{ fontSize: 16 }} />
        </IconButton>
        <MessageStatusFilter value={filterStatus} onChange={setFilterStatus} />
        <FilterMenu
          filters={filters}
          control={formCtx.control}
          filterCount={filterCount}
        />
      </Flex>
      <Table
        variant="hover"
        emptyState={
          filterCount > 0 || filterStatus !== undefined ? (
            <Text variant="muted">No messages exist matching the current filter</Text>
          ) : (
            <>
              <Text variant="muted">This endpoint has not received any messages yet</Text>
            </>
          )
        }
        response={msgs}
        requestElems={messagesCtx}
        size="sm"
        horizScroll
      >
        <Thead>
          <Tr>
            <Th />
            <Th>Event Type</Th>
            {orgSettings?.enableChannels && (
              <Th>{capitalize(stringsOverride.channelsMany)}</Th>
            )}
            <Th>Message ID</Th>
            <Th>Timestamp</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {msgs?.data.map(
            (attempt) =>
              attempt.msg && (
                <EndpointTableRow
                  key={attempt.id}
                  endpoint={endpoint}
                  attempt={attempt}
                  enableChannels={orgSettings?.enableChannels ?? false}
                />
              )
          )}
          {!msgs && (
            <Tr>
              <TableCell />
              <TableCell>
                <SkeletonText noOfLines={1} />
              </TableCell>
              {orgSettings?.enableChannels && (
                <TableCell>
                  <SkeletonText noOfLines={1} />
                </TableCell>
              )}
              <TableCell>
                <SkeletonText noOfLines={1} />
              </TableCell>
              <TableCell>
                <SkeletonText noOfLines={1} />
              </TableCell>
            </Tr>
          )}
        </Tbody>
      </Table>
      <RecoverModal
        isOpen={showRecoverModal}
        onClose={setShowRecoverModal.off}
        endpoint={endpoint}
      />
      <ReplayModal
        isOpen={showReplayModal}
        onClose={setShowReplayModal.off}
        endpoint={endpoint}
      />
      <ConfirmationDialog
        title="Deletion confirmation"
        isOpen={deleteDialogOpen}
        onCancel={() => setDeleteDialogOpen(false)}
        onOk={deleteEndpoint}
        labelOk="Delete"
        colorScheme="red"
      >
        Are you sure you would like to permanently delete this endpoint?
      </ConfirmationDialog>
    </>
  );
}

// FIXME(#8525): Add support for updating these values
const SinkConfiguration = ({ sink }: { sink: SinkOut }) => {
  return (
    <Card maxH={460} title="Configuration">
      {sink.type === "rabbitMQ" && (
        <Stack spacing={4}>
          <Stat name="AMQPS URI">
            <code>{sink.uri}</code>
          </Stat>
          <Stat name="Routing Key">
            <code>{sink.routingKey}</code>
          </Stat>
        </Stack>
      )}

      {sink.type === "sqs" && (
        <Stack spacing={4}>
          <Stat name="Region">
            <code>{sink.region}</code>
          </Stat>
          <Stat name="Queue DSN">
            <code>{sink.queueDsn}</code>
          </Stat>
          <Stat name="Access Key">{sink.accessKey}</Stat>
          <Stat name="Secret Key">{sink.secretKey}</Stat>
        </Stack>
      )}
    </Card>
  );
};

const getSinkUrl = (sink: SinkOut) => {
  switch (sink.type) {
    case "rabbitMQ":
      return sink.uri;
    case "sqs":
      return sink.queueDsn;
    case "http":
      return sink.url;
    default:
      return "";
  }
};

const endpointFromSink = (endp: EndpointOut | SinkOut): EndpointOut => {
  if ("type" in endp) {
    return {
      ...endp,
      url: getSinkUrl(endp),
    };
  }
  return endp;
};
